import $ from 'jquery';
import 'slick-carousel';
import * as thumbnails from './_thumbnails';
import * as videos from './_videos';


const bundleName = 'jamel-slider';

const navContainerClass = bundleName + '__nav';
const containerClass = bundleName + '__slides';
const sliderItemClass = bundleName + '__item';
const thumbnailsContainerClass = bundleName + '__thumbnails';

const videoPreviewClass = bundleName + '__preview';

export default function initSliders() {
    $('.' + bundleName).each(function () {

        // init sliders
        const sliderId = $(this).data('id') || null;
        const showSliderThumbnails = $(this).data('thumbnails') || false;
        const itemsLength = $(this).find('.' + sliderItemClass).length;
        const $thumbnails = $(this).find('.' + thumbnailsContainerClass);

        if (itemsLength > 1) {
            initSlick($(this));
            if (showSliderThumbnails && sliderId) {
                thumbnails.initThumbnails($thumbnails, true);
            }
        }
    });

    // reinit lazy load after initializing sliders
    if (window.lazyLoadInstance) {
        window.lazyLoadInstance.update();
    }

    // play video on preview click
    $('body').on('click', '.' + videoPreviewClass + ':not(.showed)', function () {
        videos.playVideo($(this));
    });
}

function initSlick($parent) {
    const id = $parent.data('id') || null;
    const $container = $parent.find('.' + containerClass);
    const $navContainer = $parent.find('.' + navContainerClass);
    const arrows = $parent.data('arrows') || false;
    const dots = $parent.data('dots') || false;
    const autoplay = $parent.data('autoplay') || 0;
    const autoHeight = $parent.data('auto-height') || false;
    const showThumbnails = $parent.data('thumbnails') || false;
    const variableWidth = $parent.data('variable-width') || false;
    const slidesToShow = $parent.data('slides-to-show') || 1;
    const slidesToShowMobile = $parent.data('slides-to-show-mobile') || 1;
    const infinite = $parent.data('infinite');

    $container.slick({
        nextArrow: $navContainer.find('.slick-next'),
        prevArrow: $navContainer.find('.slick-prev'),
        arrows: arrows,
        adaptiveHeight: autoHeight,
        dots: dots,
        autoplaySpeed: autoplay,
        autoplay: autoplay > 0,
        infinite: infinite,
        slidesToShow: slidesToShow,
        variableWidth: variableWidth,
        asNavFor: showThumbnails && id ? '#' + bundleName + '-' + id + ' .' + thumbnailsContainerClass : null,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: slidesToShowMobile
                }
            }
        ]
    });
}
