import 'slick-carousel';
import limitLines from './limitLines';

export default function newsAjax() {
    $('.news-slider').on('afterChange', function (event, slick, currentSlide) {
        const ajaxUrl = $(this).data('ajax-url');
        const maxResults = $(this).data('max-results');

        if (ajaxUrl) {
            const slidesCount = $(this).find('.slick-slide').length;

            if (slidesCount - currentSlide < 4  && maxResults > slidesCount) {
                newsCall($(this), ajaxUrl, slidesCount);
            }
        }
    });
}

function newsCall($parent, url, slidesCount) {

    if (!$parent.hasClass('processing')) {
        $parent.addClass('processing');

        const $slider = $parent.find('.jamel-slider__slides');

        $.ajax({
            type: 'get',
            url: url,
            data: {
                offset: slidesCount
            },
            success: function (response) {
                try {
                    const {
                        current_results: currentResults,
                        total_articles: totalArticles,
                    } = response;

                    const newsCount = currentResults.length;

                    for (let i = 0; i < newsCount; i++) {
                        const newsPage = currentResults[i];

                        const template = `
                        <div>
                            <article class="news-overview-page__item article__item jamel-slider__item fade-in">
                                <a href="${newsPage.url}">
                                    <div class="article__image">
                                        ${newsPage.image ? `
                                            <img class="${window.lazyLoadInstance ? `lazy` : ``}" src="${window.lazyLoadInstance ? newsPage.image.previewSrc : newsPage.image.src}" data-src="${newsPage.image.src}" alt="" />
                                        ` : ''}
                                    </div>
                                    <div class="article__text">
                                        <time datetime="${newsPage.datetime}" class="article__date">${newsPage.date}</time>
                                        <header class="article__title"><h2>${newsPage.title}</h2></header>
                                        <section class="article__summary limit-lines" data-max-lines="4">${newsPage.summary}</section>
                                    </div>
                                </a>
                            </article>
                        </div>
                    `;
                        $parent.data('max-results', totalArticles);
                        $slider.slick('slickAdd', template);
                    }

                    if (window.lazyLoadInstance) {
                        window.lazyLoadInstance.update();
                    }

                    $parent.removeClass('processing');
                    limitLines();

                } catch (error) {
                    console.error(error);
                    $parent.removeClass('processing');
                }

            }
        });

    }
}
