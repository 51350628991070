import $ from 'jquery';

const delay = 400;

export function stopVideos($parent) {
    const videoPreviewSelector = '.jamel-slider__preview';

    const $container = $parent.find(videoPreviewSelector);

    $container
        .removeClass('showed')
        .each(function() {
            const type = $(this).data('type');
            const $video = $(this).find(videoPreviewSelector + '__video');

            $video.fadeOut(delay);

            switch (type) {
                case 'video/mp4':
                    $video[0].pause();
                    break;
                case 'youtube':
                    const currentSrc = $video[0].src;
                    const autoplayString = '&autoplay=1';
                    if (currentSrc.includes(autoplayString)) {
                        $video[0].src = currentSrc.replace(autoplayString, '');
                    }
                    break;
            }
        });
}

export function playVideo($container) {
    const type = $container.data('type');
    const $video = $container.find('.jamel-slider__preview__video');
    $container.addClass('showed');

    $video.fadeIn(delay, function () {
        switch (type) {
            case 'video/mp4':
                $video[0].play();
                break;
            case 'youtube':
                console.log($video[0].src);
                $video[0].src += '&autoplay=1';
                break;
        }
    });
}
