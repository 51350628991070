import 'picturefill';
import $ from 'jquery';

import cookieConsent from './cookieConsent';
import videolink from './videolink';
import { navToggle } from './headerActions';
import limitLines from './limitLines';
import newsAjax from "./newsAjax";
import Animated from './animated';


// jamel-slider-bundle: import minified css or separate scss modules
// import '../../../vendor/jamel/slider-bundle/Resources/public/js/slider-bundle.min';
import initSliders from '../../../vendor/jamel/slider-bundle/Resources/ui/js/_initSliders';
import initFullscreen from '../../../vendor/jamel/slider-bundle/Resources/ui/js/_initFullscreen';

import '../../../vendor/jamel/images-lazy-load-bundle/Resources/public/js/images-lazy-load-bundle.min';

document.addEventListener('DOMContentLoaded', () => {
    cookieConsent();

    videolink();

    newsAjax();

    limitLines();

    navToggle($('.burger-menu'));

    // jamel-slider-bundle
    initSliders();
    initFullscreen();

    const animatedElements = new Animated($('.animate'));
    animatedElements.init();
});

let resizeTimeout;
window.addEventListener("resize", () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
        limitLines();
    }, 300);
});
