import $ from 'jquery';
import ScrollMagic from 'scrollmagic/scrollmagic/minified/ScrollMagic.min';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import TweenMax from 'gsap/src/uncompressed/TweenMax';
import 'scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min';

class Animated {
    constructor(container) {
        this.$container = $(container);
    }

    getOptionsForElement(element) {

        if (element.classList.contains('animate--parallax-bg')) {
            return this.getParallaxBgOptions(element);
        } else {
            return this.getDefaultOptions(element);
        }
    }

    getTriggerElement(element) {
        let triggerElement = element;
        const triggerAttribute = 'data-animate-trigger';
        if (element.hasAttribute(triggerAttribute)) {
            triggerElement =
                element.closest(element.getAttribute(triggerAttribute)) || $(element.getAttribute(triggerAttribute));
        }
        return triggerElement;
    }

    getTriggerHook(element) {
        let triggerHook = 0.5;
        if (element.hasAttribute('data-animate-hook')) {
            triggerHook = parseFloat(element.getAttribute('data-animate-hook'));
        }
        return triggerHook;
    }

    getParallaxBgOptions(element) {
        let triggerElement = this.getTriggerElement(element);
        let triggerHook = this.getTriggerHook(element);
        let duration = "200%";
        let animParam = {
            'backgroundPosition': '50% top'
        };
        animParam['ease'] = 'Linear.easeNone';

        return {
            triggerElement: triggerElement,
            triggerHook: triggerHook,
            duration: duration,
            animParam: animParam,
        };
    }

    getDefaultOptions(element) {
        const windowHeight = $(window).height();
        const speed = element.getAttribute('data-speed');

        const moveOffset = speed ? (Math.round(speed * 170)) : (Math.round(Math.random() * 100) + 70);

        let animateValue = -moveOffset;
        let animKey = element.classList.contains('animate--transformed') ? 'x' : 'y';

        let triggerElement = this.getTriggerElement(element);
        let triggerHook = this.getTriggerHook(element);

        if (element.hasAttribute('data-animate-key')) {
            animKey = element.getAttribute('data-animate-key');
        }

        let debug = false;
        if (element.hasAttribute('data-animate-debug')) {
            debug = true;
        }

        const animParam = {
            [animKey]: animateValue,
        };

        let duration = ((triggerHook > 0) ? windowHeight : 0) +
            (
                ($(element).hasClass('animate--transformed'))
                    ? $(element).outerWidth(true)
                    : $(element).outerHeight(true)
            );

        if (duration < animateValue) {
            duration = Math.abs(animateValue * speed);
        }

        if (element.hasAttribute('data-animate-trigger')) {
            // triggerHook = '0';
            if (triggerHook === 1) {
                let ratio = ($(triggerElement).outerHeight(true) - windowHeight / 2) / windowHeight;
                animParam.startAt = {
                    [animKey]: moveOffset / 2
                };
                animParam[animKey] = -(moveOffset / 2);
                if (debug) {
                    console.log(ratio, moveOffset, animParam, $(triggerElement).outerHeight(true), windowHeight);
                }
            }

            if (triggerHook > 0) {
                duration = windowHeight * 2;
            }
        }

        animParam['ease'] = 'Linear.easeNone';

        return {
            triggerElement: triggerElement,
            triggerHook: triggerHook,
            duration: duration,
            animParam: animParam,
            debug: debug
        };
    }

    init() {

        const controller = new ScrollMagic.Controller();

        this.$container.each((index, element) => {

            let options = this.getOptionsForElement(element);


            let instance = new ScrollMagic.Scene({
                triggerElement: options.triggerElement,
                triggerHook: options.triggerHook,
                duration: options.duration,
            })
                .setTween(element, 1, options.animParam)
                // .addIndicators()
                .addTo(controller);

            if (options.debug === true) {
                instance.addIndicators();
            }
        });
    }
}

export default Animated;
