import $ from 'jquery';

export default function limitLines() {
    function countLines($element) {
        return $element[0].getClientRects().length;
    }
    function checkLines($element) {
        const count = countLines($element);
        const maxLines = $($element).data('max-lines') || 3;

        if (count && count > maxLines) {
            $element
                .addClass('long')
                .css('max-height', parseInt($element.css('line-height'), 10) * maxLines)
                .attr('title', $element[0].innerHTML.replace(/<br>/g, ''));
        }
    }

    $('.limit-lines').each((index, element) => {
        checkLines($(element));
    });
}
