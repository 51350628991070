import $ from 'jquery';
import * as videos from './_videos';

const delay = 400;

export default function initFullscreen() {
    let isAnimating = false;
    const $body = $('body');

    function getElementInfo($element) {
        const elWidth = $element.innerWidth() || 0;
        const elHeight = $element.innerHeight() || 0;
        const elPosLeft = $element[0].getBoundingClientRect().left || 0;
        const elPosTop = $element[0].getBoundingClientRect().top || 0;

        return {
            width: elWidth,
            height: elHeight,
            left: elPosLeft,
            top: elPosTop,
        };
    }

    function getWindowInfo() {
        const $fullScreenOverlay = $('.gallery-overlay');

        const windowWidth = $body
            .prop('clientWidth');
        const windowHeight = window.innerHeight;

        const paddingOffsetX = parseInt($fullScreenOverlay
            .css('padding-left'), 10) * 2;
        const paddingOffsetY = parseInt($fullScreenOverlay
            .css('padding-top'), 10) * 2;

        // calculate size
        const availableWidth = windowWidth - paddingOffsetX;
        const availableHeight = windowHeight - paddingOffsetY;

        return {
            windowWidth,
            windowHeight,
            availableWidth,
            availableHeight,
            paddingOffsetX,
            paddingOffsetY
        }
    }

    function maximizeImage($clickedSlideItem = null, $parent = null) {
        let $currentSlideItem = $clickedSlideItem;

        $parent = $parent ? $parent : $clickedSlideItem.closest('.jamel-slider');

        if ($clickedSlideItem === null) {
            const $currentSlide = $parent.find('.slick-current').length > 0
                ? $parent.find('.slick-current')
                : $parent.find('.gallery__item, .jamel-slider__item');
            $currentSlideItem = $currentSlide.find('.gallery__image, .gallery__video, .jamel-slider__image');
        }

        if (!isAnimating && $currentSlideItem.find('img.lazy:not(.loaded)').length < 1) {
            isAnimating = true;

            videos.stopVideos($clickedSlideItem || $parent);

            // disable scroll
            $body.addClass('hidden-scrollbar');

            const fullScreenOverlay = document.createElement('div');
            fullScreenOverlay.className = 'gallery-overlay';

            $(fullScreenOverlay).data('target-slider', ($currentSlideItem.closest('.slick-initialized').length > 0) ? $currentSlideItem.closest('.slick-initialized') : $parent);

            // add image caption
            const caption = $currentSlideItem.attr('title');
            if (caption && caption.length > 0) {
                const imageCaption = document.createElement('div');
                imageCaption.classList.add('gallery-overlay__caption');
                imageCaption.innerHTML = caption;
                fullScreenOverlay.appendChild(imageCaption);
            }

            // add close button
            const closeButton = document.createElement('div');
            closeButton.classList.add('gallery-overlay__close-button');
            closeButton.innerHTML = '<i class="icon icon-cross"></i>';
            fullScreenOverlay.appendChild(closeButton);

            // add navigation arrows if there's more slides than 1
            if ($parent.find('.jamel-slider__item').length > 1) {
                const navigationArrows = document.createElement('div');
                navigationArrows.classList.add('gallery-overlay__navigation-arrows');
                navigationArrows.innerHTML =
                    '<button type="button" class="slick-prev slick-arrow btn"><i class="icon icon-arrow-prev"></i></button>' +
                    '<button type="button" class="slick-next slick-arrow btn"><i class="icon icon-arrow-next"></i></button>';
                fullScreenOverlay.appendChild(navigationArrows);
            }

            // add slides container
            const fullScreenSlidesContainer = document.createElement('div');
            fullScreenSlidesContainer.className = 'gallery-overlay__slides';
            fullScreenOverlay.appendChild(fullScreenSlidesContainer);

            $body[0].insertBefore(fullScreenOverlay, $body[0].firstChild);

            cloneItemMedia($currentSlideItem, fullScreenOverlay);

            $(document)
                .bind('keyup', (e) => {
                    if (!isAnimating) {
                        const $slider = $(fullScreenOverlay).find('.slick-slider');
                        switch (e.which) {
                            case 27:
                                minimizeImage($(fullScreenOverlay));
                                break;
                            case 37:
                                $slider.slick('slickPrev');
                                break;
                            case 39:
                                $slider.slick('slickNext');
                                break;
                        }
                    }
                });

        }
    }

    function cloneItemMedia($sliderItem, fullScreenOverlay) {
        // prepare all slides if there are some other than the one that was clicked
        const $parent = $sliderItem.closest('.jamel-slider');
        const $allSliderItems = $parent.find('.jamel-slider__item').not(".slick-cloned .jamel-slider__item");
        const currentSlideIndex = $sliderItem.closest('.slick-slide').data('slick-index');

        $allSliderItems.each((index, singleSliderItem) => {
            const $slideClone = $('<div>');
            $slideClone.addClass('gallery-overlay__slides-item');

            appendFullImage($(singleSliderItem), $slideClone);

            $slideClone.appendTo($(fullScreenOverlay).find('.gallery-overlay__slides'));
        });

        // prepare clicked slide
        const $currentSlideClone = $('<div>');

        appendFullImage($sliderItem, $currentSlideClone, true);

        const $currentSlideImage = $sliderItem.find('img');

        // if current slide is image (or video preview image) then animate it
        if ($currentSlideImage.length > 0) {

            const animationData = getAnimationData($currentSlideImage);

            $currentSlideClone
                .addClass('gallery-overlay__item-clone')
                .css('width', animationData.imgWidth)
                .css('height', animationData.imgHeight);

            // animate current slide
            $currentSlideClone
                .addClass('gallery-overlay__item-current')
                .css('transform', `translate(${animationData.translateX}px, ${animationData.translateY}px) scale(${1 / animationData.scaleX}, ${1 / animationData.scaleY})`)
                .data('initial-scale-x', animationData.scaleX)
                .data('initial-scale-y', animationData.scaleY)
                .data('initial-translate-x', animationData.translateX)
                .data('initial-translate-y', animationData.translateY)
                .data('initial-slide-index', currentSlideIndex)
                .appendTo($(fullScreenOverlay));

            const newTranslateX = (animationData.availableWidth - $currentSlideClone[0].clientWidth) / 2;
            const newTranslateY = (animationData.availableHeight - $currentSlideClone[0].clientHeight) / 2;

            setTimeout(() => {
                window.requestAnimationFrame(() => {
                    $currentSlideClone
                        .addClass('fullscreen')
                        .css('transform', `translate(${newTranslateX}px, ${newTranslateY}px) scale(1, 1)`);
                });
            }, 0);

        }

        setTimeout(() => {
            isAnimating = false;
            if (window.lazyLoadInstance) {
                window.lazyLoadInstance.update();
            }

            if ($parent.data('fullscreen-slider')) {
                initFullscreenSlider($(fullScreenOverlay), currentSlideIndex);
            }

        }, ($currentSlideImage.length > 0) ? delay : 0);

    }

    function getAnimationData($image) {
        const windowInfo = getWindowInfo();

        const windowWidth = windowInfo.windowWidth;
        const windowHeight = windowInfo.windowHeight;

        const paddingOffsetX = windowInfo.paddingOffsetX;
        const paddingOffsetY = windowInfo.paddingOffsetY;

        // get current image size
        // or if image position is absolute
        // (which means it's positioned relative to some div)
        // then get that div's size instead
        let currentSlideMediaInfo;
        if($image.css('position') === 'absolute') {
            // find div that image position is relative to
            const $relativeParent = $image
                .parents()
                .filter(function() {
                    return $(this).css('position') === 'relative';
                }).first();
            currentSlideMediaInfo = getElementInfo($relativeParent);
        } else {
            currentSlideMediaInfo = getElementInfo($image);
        }

        const translateX = currentSlideMediaInfo.left - (paddingOffsetX / 2);
        const translateY = currentSlideMediaInfo.top - (paddingOffsetY / 2);

        // calculate size
        const availableWidth = windowInfo.availableWidth;
        const availableHeight = windowInfo.availableHeight;

        const naturalImgWidth = $image[0].naturalWidth;
        const naturalImgHeight = $image[0].naturalHeight;

        const ratio = calculateRatio(naturalImgHeight, naturalImgWidth);

        // square image
        let imgWidth = Math.min(availableWidth, availableHeight);
        let imgHeight = Math.min(availableWidth, availableHeight);

        // horizontal or portrait image
        if (naturalImgHeight !== naturalImgWidth) {
            imgWidth = (naturalImgHeight > naturalImgWidth)
                ? (windowHeight - paddingOffsetX) * ratio
                : availableWidth;
            imgHeight = (naturalImgWidth > naturalImgHeight)
                ? (windowWidth - paddingOffsetY) * ratio
                : availableHeight;
        }

        // scale image down to fit available space
        if (imgHeight > availableHeight) {
            const scaleDownRatio = availableHeight / imgHeight;
            imgHeight = availableHeight;
            imgWidth *= scaleDownRatio;
        }
        if (imgWidth > availableWidth) {
            const scaleDownRatio = availableWidth / imgWidth;
            imgWidth = availableWidth;
            imgHeight *= scaleDownRatio;
        }

        const scaleX = imgWidth / currentSlideMediaInfo.width;
        const scaleY = imgHeight / currentSlideMediaInfo.height;

        return {
            scaleX,
            scaleY,
            translateX,
            translateY,
            availableHeight,
            availableWidth,
            imgWidth,
            imgHeight
        }
    }

    function initFullscreenSlider($fullScreenOverlay, currentSlideIndex) {
        const $container = $fullScreenOverlay.find('.gallery-overlay__slides');
        const $targetSlider = $($fullScreenOverlay.data('target-slider'));

        $container
            .on('init', () => {
                $container.addClass('visible');
                $fullScreenOverlay
                    .find('.gallery-overlay__item-clone')
                    .addClass('is-hidden');
                if ($targetSlider.length > 0) {
                    $targetSlider.slick('slickGoTo', currentSlideIndex);
                }
            })

            .slick({
                nextArrow: $fullScreenOverlay.find('.slick-next'),
                prevArrow: $fullScreenOverlay.find('.slick-prev'),
                arrows: true,
                adaptiveHeight: false,
                dots: false,
                autoplay: false,
                infinite: true,
                slidesToShow: 1,
                speed: delay,
                cssEase: 'cubic-bezier(.29,.44,.24,1)',
                useTransform: true,
                accessibility: true,
                initialSlide: currentSlideIndex,
                asNavFor: ($targetSlider.length > 0) ? $targetSlider[0] : null
            });


    }

    // create new img element with bigger image to lazy load
    function appendFullImage($originalSlide, $slideClone, imageOnly = false) {
        const $originalImage = $originalSlide.find('img');
        const fullImageSrc = $originalImage.data('full-src');

        if (fullImageSrc) {
            const fullImageSrcSet = $originalImage.data('full-srcset');

            const $fullImage = $(`<img
                        data-srcset="${fullImageSrcSet}"
                        alt="${$originalImage.attr('alt')}"
                        data-src="${fullImageSrc}">`)
                .appendTo($slideClone);

            // if lazy load exists: update lazy load with new element
            if (window.lazyLoadInstance) {
                $fullImage
                    .attr('src', $originalImage.attr('src'))
                    .addClass('lazy lazy--no-blur');
            } else {
                // add full src to image
                $fullImage
                    .attr('srcset', fullImageSrcSet)
                    .attr('src', fullImageSrc + '?full');
            }

        } else {
            // clone existing element
            const $originalMedia = $originalSlide.find('.gallery__image');
            if ($originalMedia.length > 0) {
                $slideClone.append($originalMedia.html());
                if ($originalMedia.hasClass('jamel-slider__preview')) {
                    $slideClone.addClass('jamel-slider__preview');
                    $slideClone.data('type', $originalMedia.data('type'));
                }
            } else {
                if (imageOnly) {
                    $slideClone.append($originalImage.clone());
                } else {
                    $slideClone.append($originalSlide.html());
                }
            }
            // remove inline styles from media element (slick transformations etc.)
            $slideClone
                .html($originalSlide.html())
                .find('img')
                .removeAttr('style');
        }
    }

    function calculateRatio(a, b) {
        return (a > b) ? b / a : a / b;
    }

    function minimizeImage(fullScreenOverlay = null) {
        if (!isAnimating) {
            isAnimating = true;

            const $overlay = fullScreenOverlay ? $(fullScreenOverlay) : $('.gallery-overlay');
            const $currentClonedSlide = $overlay.find('.gallery-overlay__item-clone');
            const slideIndex = $overlay.find('.slick-current').data('slick-index') || 0;

            const initialSlideIndex = $currentClonedSlide.data('initial-slide-index') || 0;
            const $targetSlider = $($overlay.data('target-slider'));
            const $targetImage = $targetSlider.find('.slick-current').find('.jamel-slider__item img');

            let scaleX;
            let scaleY;
            let translateX;
            let translateY;

            if ($targetImage.length > 0) {
                $currentClonedSlide
                    .html('')
                    .append($targetImage.clone())
                    .find('img')
                    .removeAttr('style');

                const animationData = getAnimationData($targetImage);

                $currentClonedSlide
                    .css('width', animationData.imgWidth)
                    .css('height', animationData.imgHeight)

                const newTranslateX = (animationData.availableWidth - $currentClonedSlide[0].clientWidth) / 2;
                const newTranslateY = (animationData.availableHeight - $currentClonedSlide[0].clientHeight) / 2;

                $currentClonedSlide
                    .css('transform', `translate(${newTranslateX}px, ${newTranslateY}px) scale(1, 1)`);

                // reading offsetHeight forces browser to reflow element (ignoring transition)
                $currentClonedSlide[0].offsetHeight;

                scaleX = animationData.scaleX || 0;
                scaleY = animationData.scaleY || 0;
                translateX = animationData.translateX || 0;
                translateY = animationData.translateY || 0;

            } else {
                scaleX = $currentClonedSlide.data('initial-scale-x') || 0;
                scaleY = $currentClonedSlide.data('initial-scale-y') || 0;
                translateX = $currentClonedSlide.data('initial-translate-x') || 0;
                translateY = $currentClonedSlide.data('initial-translate-y') || 0;
            }

            $currentClonedSlide.removeClass('is-hidden');

            $overlay.addClass('closing');

            $currentClonedSlide
                .css('transform', `translate(${translateX}px, ${translateY}px) scale(${1 / scaleX}, ${1 / scaleY})`)
                .removeClass('fullscreen');

            setTimeout(() => {
                $overlay.remove();
                $body
                    .removeClass('hidden-scrollbar');
                isAnimating = false;
            }, delay);

            $(document)
                .unbind('keyup');
        }
    }

    function appendNoScrollStyle() {
        const $noScrollStyle = $('#jamel-noscroll');
        const styleCss = `.hidden-scrollbar{padding-right:${
            window.innerWidth - document.documentElement.clientWidth
        }px;}`;

        if ($noScrollStyle.length) {
            $noScrollStyle.html(styleCss);
        } else {
            $('head')
                .append(`<style id="jamel-noscroll" type="text/css">${styleCss}</style>`);
        }
    }

    // maximize image on click on maximize button
    $body.on('click', '.btn--maximize', (event) => {
        maximizeImage(null, $(event.target).closest('.jamel-slider'));
    });

    // toggle slider fullscreen on click on slider maximize button
    $body.on('click', '.toggle-fullscreen', function (e) {
        e.stopImmediatePropagation();
        const $parent = $(this).closest('.jamel-slider');
        if ($body.find('.gallery-overlay').length === 0) {
            maximizeImage(null, $parent);
        } else {
            minimizeImage();
        }
    });

    // prevent slide dragging from firing maximize event
    let dragged = 0;
    const threshold = 5;
    // maximize image on click on gallery image
    $body
        .on('mousedown', '.gallery-maximize', function () {
            dragged = 0;
        })
        .on('mousemove', '.gallery-maximize', function () {
            dragged++;
        })
        .on('mouseup', '.gallery-maximize', function (e) {
            if (dragged < threshold) {
                e.preventDefault();
                maximizeImage($(e.currentTarget));
            }
            dragged = 0;
        });

    $body.on('click', '.gallery-overlay', (e) => {
        const unclickableClass = 'gallery-overlay__item-clone';
        const unclickableClass2 = 'jamel-slider__preview';
        const unclickableClass3 = 'gallery-overlay__navigation-arrows';
        if (
            !$(e.target)
                .hasClass(unclickableClass)
            &&
            !$(e.target)
                .hasClass(unclickableClass2)
            &&
            !$(e.target)
                .hasClass(unclickableClass3)
            && $(e.target)
                .closest(`.${unclickableClass}`).length === 0
            && $(e.target)
                .closest(`.${unclickableClass3}`).length === 0
            && !isAnimating
        ) {
            minimizeImage();
        }
    });

    appendNoScrollStyle();

    $(window)
        .on('resize', () => {
            appendNoScrollStyle();
        });
}

// TODO: Nieprzekraczanie maksymalnych rozmiarów obrazka
