import $ from 'jquery';

export function navToggle($container) {
    $container.on('click', (event) => {
        event.preventDefault();
        const $header = $(event.currentTarget).prev('.main-header__nav');
        $header.toggleClass('opened');
    });
}

