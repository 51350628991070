import $ from 'jquery';

const delay = 400;

export function initThumbnailsClone($thumbnailsContainer) {
    initThumbnails($thumbnailsContainer, false);

    setTimeout(function () {
        $thumbnailsContainer.addClass('jamel-slider__thumbnails--visible');
    }, delay);
}

export function initThumbnails($thumbnails, slideDown) {
    const $parent = $thumbnails.closest('.jamel-slider');
    const $container = $parent.find('.jamel-slider__slides');

    const itemsLength = $parent.find('.jamel-slider__thumbnails__item').length;
    const scroll = itemsLength > 5;
    const currentSlideIndex = $container.find('.slick-current').data('slick-index');

    if ($parent && $container && itemsLength > 1) {

        if (scroll) {
            $thumbnails.css('overflow', 'hidden');
        }

        $thumbnails
            .on('init', function () {
                // 0 timeout is needed as a workaround to slick bug
                setTimeout(function () {
                    $thumbnails
                        .slick('slickSetOption', 'asNavFor', $container);
                    $container.slick('slickSetOption', 'asNavFor', $thumbnails);
                }, 0);
            })
            .slick({
                arrows: false,
                dots: false,
                centerMode: scroll,
                slidesToShow: scroll ? 5 : itemsLength,
                slidesToScroll: 1,
                focusOnSelect: true,
                infinite: scroll,
                centerPadding: '-15px',
                speed: 500,
                initialSlide: currentSlideIndex
            });

        if (slideDown) {
            $thumbnails.slideDown({
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            });
        }
    }
}
